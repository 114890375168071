<template>
  <div class="two irohamaru">
    <div class="title">
      {{ params.post }}
    </div>
    <div class="content">
      {{ params.duty }}
    </div>
    <div class="information">め-るばっくす：{{ params.email }}</div>
    <div class="button">
      <div class="btn flex row-center col-center" @click="handleDetails">
        詳細を確認
      </div>
      <!--      <el-button type="primary" round @click="handleDetails">詳細を確認</el-button>-->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    params: {
      type: Object
    }
  },
  data() {
    return {}
  },
  methods: {
    handleDetails() {
      this.$emit('getDetails', this.params)
    }
  }
}
</script>
<style scoped lang="scss">
.two {
  width: 620px;
  height: 480px;
  box-sizing: border-box;
  padding: 104px 34px 53px 34px;
  border-radius: 18px;
  background: #000;
  margin-right: 20px;
  .title {
    color: $light-color;
    font-size: 28px;
    font-weight: 500;
    line-height: 35px; /* 125% */
    letter-spacing: 0.196px;
    margin-bottom: 17px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .content {
    color: $light-color;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px; /* 147.059% */
    margin-bottom: 17px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .information {
    color: $light-color;
    font-size: 17px;
    font-weight: 500;
    line-height: 25px; /* 147.059% */
  }
  .button {
    //margin-top: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      width: 138px;
      height: 42px;
      border-radius: 980px;
      background: #bbb290;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      color: rgba(255, 255, 255, 1);
      font-weight: bold;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.3px;
      cursor: pointer;
      position: relative;
      z-index: 1000;
      bottom: -7vw;
    }
  }
}
::v-deep .el-button--medium.is-round {
  width: 138px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px 26px;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  background: #bbb290;
}
::v-deep .el-button--primary {
  border-color: #bbb290;
}
</style>
