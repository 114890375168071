<template>
  <div class="custom-pagination ">
    <div
      @click="pre"
      class="page "
      v-if="isHoverLeft"
      @mouseenter="handleMouseEnterSort(1)"
      @mouseleave="handleMouseLeaveSort(1)"
      :class="realIndex === 0 ? 'disabled' : 'highlight hover'"
    >
      <span
        class="iconfont icon-zuojiantou icon-button"
        style="margin-right: 2px;margin-top: 2px"
      ></span>
    </div>
    <div
      @click="pre"
      class="page"
      v-if="!isHoverLeft"
      @mouseenter="handleMouseEnterSort(1)"
      @mouseleave="handleMouseLeaveSort(1)"
      :class="realIndex === 0 ? 'disabled' : 'highlight'"
    >
      <span
        class="iconfont icon-zuojiantou icon-button"
        style="margin-right: 2px;margin-top: 2px"
      ></span>
    </div>

    <div
      @click="next"
      @mouseenter="handleMouseEnterSort(2)"
      @mouseleave="handleMouseLeaveSort(2)"
      class="page"
      v-if="!isHoverRight"
      :class="realIndex === recruitList.length - 3 ? 'disabled' : 'highlight'"
    >
      <span
        class="iconfont icon-youjiantou icon-button"
        style="margin-left: 2px;margin-top: 2px"
      ></span>
    </div>
    <div
      @click="next"
      v-if="isHoverRight"
      @mouseenter="handleMouseEnterSort(2)"
      @mouseleave="handleMouseLeaveSort(2)"
      class="page"
      :class="realIndex === recruitList.length - 3 ? 'disabled' : 'highlight hover'"
    >
      <span
        class="iconfont icon-youjiantou icon-button"
        style="margin-left: 2px;margin-top: 2px"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    recruitList: {
      type: Array
    }
  },
  data() {
    return {
      realIndex: 0,
      isHoverLeft: false,
      isHoverRight: false
    }
  },

  mounted() {
    // if (this.recruitList.length >= 6) {
    //   this.realIndex = 3
    // } else {
    //   this.realIndex = 2
    // }
  },
  methods: {
    getIndex(e) {
      console.log(e)
      console.log(1111111111)

      // this.realIndex = e
    },
    pre() {
      this.$nextTick(() => {
        if (this.realIndex > 0) {
          this.realIndex--
          this.$emit('pre', this.realIndex)
        }
      })
    },
    next() {
      const that = this
      that.$nextTick(() => {
        if (that.realIndex < Number(that.recruitList.length) - 3) {
          that.realIndex++
          this.$emit('next', this.realIndex)
        }
      })
    },
    handleMouseEnterSort(e) {
      if (e === 1) {
        this.isHoverLeft = true
      } else {
        this.isHoverRight = true
      }
    },
    handleMouseLeaveSort(e) {
      if (e === 1) {
        this.isHoverLeft = false
      } else {
        this.isHoverRight = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.custom-pagination {
  margin-top: 45px;
  margin-right: 322px;
  display: flex;
  justify-content: flex-end;
  gap: 14px;
  .page {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    //background: rgba(66, 66, 69, 0.72);
    margin-right: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    //color: #ea5504;
    //cursor: pointer;
  }
}
.disabled {
  color: #4f4f4f;
  cursor: default;
  opacity: 0.36;
  background: rgba(66, 66, 69, 0.72);
}
.highlight {
  cursor: pointer;
  background: rgba(66, 66, 69, 0.72);
}
.hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
    rgba(66, 66, 69, 0.72);
}
</style>
