<template>
  <div>
    <div class="one-or-three irohamaru">
      <div v-if="recruitList.length === 1">
        <div v-for="(item, index) in recruitList" :key="index">
          <one @getDetails="getDetails" :params="item"></one>
        </div>
      </div>
      <div class="two-card" v-if="recruitList.length === 2">
        <div v-for="(item, index) in recruitList" :key="index">
          <two @getDetails="getDetails" :params="item"></two>
        </div>
      </div>

      <div class="three-card" v-if="recruitList.length === 3">
        <div v-for="(item, index) in recruitList" :key="index">
          <three @getDetails="getDetails" :params="item"></three>
        </div>
      </div>
    </div>
    <div class="more irohamaru" v-if="recruitList.length > 3">
      <joinUs ref="joinus" @getDetails="getDetails" :recruitList="recruitList"></joinUs>
    </div>
    <DDialog ref="DDialog" :dialogVisible="dialogVisible" @close="close">
      <div class="dialog-card irohamaru" id="ddialog">
        <div class="title">{{ recruitDetails.post }}</div>
        <div class="card">
          <div class="content">
            <span>仕事の责任：</span>
            <span>{{ recruitDetails.postRequirement }}</span>
          </div>
          <div class="content">
            <span>職務要件：</span>
            <span>{{ recruitDetails.duty }}</span>
          </div>
          <div class="content" v-if="recruitDetails.welfare">
            <span>福利厚生：</span>
            <span>{{ recruitDetails.welfare }}</span>
          </div>
          <div class="content-bottom">
            <span>配達メ-ルボックス：</span>
            <span
              ><span>{{ recruitDetails.email }}</span></span
            >
          </div>
        </div>
        <div class="footer-bottom">
          <div class="footer-button">
            <div class="button" @click="pre">
              <div
                class="left"
                :class="
                  realIndex > 0 && realIndex < recruitList.length
                    ? 'highlight'
                    : realIndex === 0
                    ? 'noMore'
                    : ''
                "
                v-if="!isHoverLeft"
                @mouseenter="handleMouseEnterSort(1)"
                @mouseleave="handleMouseLeaveSort(1)"
              >
                <span class="iconfont icon-zuojiantou icon-button" style="margin-right: 4px"></span>
              </div>
              <div
                class="left hover"
                v-if="isHoverLeft"
                @mouseenter="handleMouseEnterSort(1)"
                @mouseleave="handleMouseLeaveSort(1)"
              >
                <span class="iconfont icon-zuojiantou icon-button" style="margin-right: 4px"></span>
              </div>
              <div style="cursor: pointer;">上一篇</div>
            </div>
            <div class="button" @click="next">
              <div style="cursor: pointer;">
                下一篇
              </div>
              <div
                class="right"
                :class="
                  realIndex + 1 < recruitList.length
                    ? 'highlight'
                    : realIndex + 1 === recruitList.length
                    ? 'noMore'
                    : ''
                "
                v-if="!isHoverRight"
                @mouseenter="handleMouseEnterSort(2)"
                @mouseleave="handleMouseLeaveSort(2)"
              >
                <span class="iconfont icon-youjiantou icon-button" style="margin-left: 4px"></span>
              </div>
              <div
                class="right hover"
                v-if="isHoverRight"
                @mouseenter="handleMouseEnterSort(2)"
                @mouseleave="handleMouseLeaveSort(2)"
              >
                <span class="iconfont icon-youjiantou icon-button" style="margin-left: 4px"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DDialog>
  </div>
</template>

<script>
import one from './one.vue'
import two from './two.vue'
import three from './three.vue'
import joinUs from '@/components/Moudle/ContactUs/joinUs.vue'
import DDialog from '@/components/Common/Dialog/DDialog.vue'
import { getRecruitDetails } from '@/api/contactUs'
export default {
  components: {
    one,
    two,
    three,
    joinUs,
    DDialog
  },
  props: {
    recruitList: {
      type: Array
    }
  },
  data() {
    return {
      listData: [1, 2],
      dialogVisible: false,
      realIndex: 0,
      itemStratIndex: 0,
      itemEndIndex: 3,
      isHoverLeft: false,
      isHoverRight: false,
      recruitDetails: {},
      id: ''
    }
  },
  methods: {
    backTop() {
      this.$nextTick(() => {
        this.$refs.DDialog.toTop()
      })
    },
    pre() {
      if (this.realIndex > 0) {
        this.getDetails(this.recruitList[--this.realIndex])
        this.backTop()
      }
    },
    next() {
      if (this.realIndex + 1 < this.recruitList.length) {
        this.getDetails(this.recruitList[++this.realIndex])
        this.backTop()
      }
    },
    handleMouseEnterSort(e) {
      if (e === 1) {
        this.isHoverLeft = true
      } else {
        this.isHoverRight = true
      }
    },
    handleMouseLeaveSort(e) {
      if (e === 1) {
        this.isHoverLeft = false
      } else {
        this.isHoverRight = false
      }
    },
    getDetails(e) {
      this.id = e.id
      for (let i = 0; i < this.recruitList.length; i++) {
        if (this.recruitList[i].id === e.id) {
          this.realIndex = i
        }
      }
      getRecruitDetails(e.id).then(res => {
        this.recruitDetails = res.data
        this.dialogVisible = true
      })
    },
    close() {
      this.dialogVisible = false
      this.$refs.joinus.getIndex(this.realIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.one-or-three {
  display: flex;
  align-items: center;
  justify-content: center;
  .two-card {
    display: flex;
    align-items: center;
    margin-left: 36px;
  }
  .three-card {
    margin-left: 80px;
    display: flex;
    align-items: center;
  }
}
.dialog-card {
  padding: 83.5px 130px 110px 130px;
  box-sizing: border-box;
  .title {
    color: #161617;
    font-size: 56px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.196px;
    margin-bottom: 60px;
  }
  .card {
    width: 1180px;
    //height: 660px;
    border-radius: 28px;
    border: 1px solid rgba(187, 178, 144, 0.2);
    background: rgba(255, 255, 255, 0.3);
    padding: 60px 100px;
    box-sizing: border-box;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: self-start;
      white-space: pre-wrap;
      color: #161617;
      font-size: 24px;
      font-weight: 500;
      line-height: 44px; /* 183.333% */
      margin-bottom: 50px;
    }
    .content-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: self-start;
      white-space: pre-wrap;
      color: #161617;
      font-size: 24px;
      font-weight: 500;
      line-height: 44px; /* 183.333% */
    }
  }
  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    .footer-button {
      margin-top: 70px;
      width: 652px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #161617;
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      .button {
        display: flex;
        align-items: center;
        .left {
          cursor: pointer;
          width: 54px;
          height: 54px;
          border-radius: 50%;
          //background: rgba(66, 66, 69, 0.72);
          margin-right: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon-button {
            font-size: 25.5px;
            color: $light-color;
          }
        }
        .right {
          cursor: pointer;
          width: 54px;
          height: 54px;
          border-radius: 50%;
          //background: rgba(66, 66, 69, 0.72);
          margin-left: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon-button {
            font-size: 25.5px;
            color: $light-color;
          }
        }
      }
    }
  }
}
.highlight {
  cursor: pointer;
  background: rgba(66, 66, 69, 0.72);
}
.hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
    rgba(66, 66, 69, 0.72);
}
.noMore {
  cursor: default;
  opacity: 0.36;
  background: rgba(66, 66, 69, 0.72);
}
</style>
